import { Box, Icon, Text } from '@profitowi/component-library';
import img_family from 'assets/family.png';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { getLinkConfirmation } from 'services/verification';

import dotsImg from '../../assets/dots.svg';

export default function Confirmation() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { data, isSuccess } = useQuery<Boolean, AxiosError>('confirmationProve', () =>
    getLinkConfirmation(code || '')
  );

  return (
    <Box className="!p-16">
      <div className="flex flex-col gap-12">
        <div className="flex w-fit items-center">
          <img src={dotsImg} alt="" />
          <Text weight="bold" size="2xl" className="mx-8 text-center text-primary">
            Potwierdzenie wybrania zgód marketingowych
          </Text>
          <img src={dotsImg} alt="" />
        </div>
        <div className="flex flex-row items-center justify-between relative my-8 mx-24">
          <div className="flex flex-col gap-8">
            {/* prettier-ignore */}
            <Text size="lg" weight="bold" className="w-full">
                Witamy w PROFITOWI!<br/><br/>
                {isSuccess ?
                <>
                {(data) ? <>Twoje zgody zostały potwierdzone.</> : 
                <>Niestety nie udało się potwierdzić Twoich zgód.</> }
                </>
                :
                <>Trwa proces potwierdzania zgód...</>
                }
              <br/><br/>
                Pozdrawiamy,<br/>
                Zespół PROFITOWI           
            </Text>
          </div>
          <div className="flex">
            <img
              alt=""
              src={img_family}
              className="-mr-[140px] mt-auto z-[1] h-[260px] block shrink-0"
            />
            <div className="bg-primary h-[320px] w-[180px] z-0 block shrink-0"></div>
          </div>
        </div>
        <div className="flex gap-16">
          <div>
            <Text size="sm" weight="bold" className="text-secondary">
              PROFITOWI S.A. Siedziba: ul. Giełdowa 7/9, 01-211 Warszawa. Sąd rejestrowy i nr KRS:
              Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego
              Rejestru Sądowego, nr 0000693553. Kapitał zakładowy: 15.000.000zł. Informacje o
              akcjonariuszu: 100% Vienna Life TU na Życie S.A. Vienna Insurance Group
            </Text>
          </div>
          <div>
            <Text weight="bold" className="text-teal-400 whitespace-nowrap">
              Więcej na www.profitowi.pl
            </Text>
            <div className="flex flex-row items-center mt-4">
              <a className="mr-4 w-[26px]" href="/#">
                <Icon className="w-[26px]" custom name="logo" />
              </a>
              <a className="mr-4" href="/#">
                <Icon className="text-2xl text-secondary " name="facebook" />
              </a>
              <a className="mr-4" href="/#">
                <Icon className="text-2xl text-secondary" name="instagram" />
              </a>
              <a className="mr-4" href="/#">
                <Icon className="text-2xl text-secondary" name="linkedin" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
