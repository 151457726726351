import { Box, Icon, Text } from '@profitowi/component-library';
import img_family from 'assets/family.png';
import woman from 'assets/woman.png';

import PageContent from 'components/PageContent';

export default function About() {
  return (
    <PageContent title="O Profitowi">
      <Box className="!p-16 m-auto h-full">
        <div className="grid grid-cols-1 gap-20">
          <div className="flex flex-row items-center justify-between relative my-8">
            <Text size="lg" weight="bold" className=" w-3/6 z-20">
              Ułatwiamy podjęcie trudnych decyzji w sprawie ubezpieczeń, kredytów i oszczędności
            </Text>
            <div>
              <div className="mr-8 ml-8 bottom-0 right-0 w-60 h-60 object-scale-down" />
              <div className="absolute bg-primary h-72 w-48 bottom-0 right-0"></div>
              <img alt="" src={img_family} className="absolute mr-8 bottom-0 right-0 w-60" />
            </div>
          </div>
          <div className="grid gap-8 grid-cols-3">
            <div className="flex flex-row">
              <Icon className="h-10 mr-3" custom name="chat-circled" />
              <Text weight="bold">Rozmawiamy i słuchamy Twoich potrzeb</Text>
            </div>
            <div className="flex flex-row">
              <Icon className="h-10 mr-3" custom name="dotted-plus-circled" />
              <Text weight="bold">Porównujemy wszystkie oferty na rynku</Text>
            </div>
            <div className="flex flex-row">
              <Icon className="h-10 mr-3" custom name="arrow-right-circled" />
              <Text weight="bold">Przedstawiamy 3 najlepsze opcje oraz jasną rekomendację</Text>
            </div>
            <div className="flex flex-row">
              <Icon className="h-10 mr-3" custom name="chat-circled" />
              <Text weight="bold">Nie namawiamy, zawsze to Ty decydujesz</Text>
            </div>
            <div className="flex flex-row">
              <Icon className="h-10 mr-3" custom name="chat-circled" />
              <Text weight="bold">Z Twoimi znajomymi pracujemy tak jak z Tobą</Text>
            </div>
            <div className="flex flex-row">
              <Icon className="h-10 mr-3" custom name="chat-circled" />
              <Text weight="bold">Mamy Ekspertów we wszystkich dziedzinach</Text>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-6 items-center">
            <div className="grid grid-cols-1 gap-6">
              <div>
                <Text size="xl" weight="bold" className="text-primary">
                  72
                </Text>
                <Text size="sm" weight="bold">
                  Banki i towarzystwa ubezpieczeniowe w jednym miejscu
                </Text>
              </div>
              <div>
                <Text size="xl" weight="bold" className="text-primary">
                  300
                </Text>
                <Text size="sm" weight="bold">
                  Doświadczonych doradców
                </Text>
              </div>
              <div>
                <Text size="xl" weight="bold" className="text-primary">
                  3+1
                </Text>
                <Text size="sm" weight="bold">
                  Przedstawiamy 3 najlepsze oferty z 1 rekomendowaną
                </Text>
              </div>
            </div>
            <div className="flex flex-col col-span-2 relative mt-12">
              <div className="flex items-center justify-end">
                <img alt="" src={woman} className="z-10" />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-6">
              <div>
                <Text size="xl" weight="bold" className="text-md text-primary whitespace-nowrap">
                  40 000+
                </Text>
                <Text size="sm" weight="bold">
                  Klientów rocznie korzysta z naszego wsparcia
                </Text>
              </div>
              <div>
                <Text size="xl" weight="bold" className="text-primary">
                  10+
                </Text>
                <Text size="sm" weight="bold">
                  lat doświadczenia naszych ekspertów
                </Text>
              </div>
            </div>
          </div>
          <div>
            <Text size="sm" weight="bold" className="text-secondary">
              PROFITOWI S.A. Siedziba: ul. Giełdowa 7/9, 01-211 Warszawa. Sąd rejestrowy i nr KRS:
              Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego
              Rejestru Sądowego, nr 0000693553. Kapitał zakładowy: 15.000.000zł. Informacje o
              akcjonariuszu: 100% Vienna Life TU na Życie S.A. Vienna Insurance Group.
            </Text>
            <div className="flex flex-row items-center mt-4">
              <a href="/#">
                <Icon className="h-6 mr-4" custom name="logo" />
              </a>
              <a href="/#">
                <Icon className="text-2xl mr-4 text-secondary " name="facebook" />
              </a>
              <a href="/#">
                <Icon className="text-2xl mr-4 text-secondary" name="instagram" />
              </a>
              <a href="/#">
                <Icon className="text-2xl text-secondary" name="linkedin" />
              </a>
            </div>
          </div>
        </div>
      </Box>
    </PageContent>
  );
}
